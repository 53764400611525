import React, { Suspense, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import Loader from './Loader'
const Spline = React.lazy(() => import('@splinetool/react-spline'))

const url = 'https://prod.spline.design/0gnz3Z-ySpWX7JDL/scene.splinecode'
export default function App() {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  })
  const [loading, setLoading] = useState(true)
  return (
    <Suspense fallback={<Loader />}>
      {loading && <Loader />}
      <Spline
        scene={url}
        ref={ref}
        className={`transition-opacity duration-1000 ${
          inView ? 'opacity-100' : 'opacity-0'
        }`}
        onLoad={() => setLoading(false)}
      />
    </Suspense>
  )
}
