import React, { Component } from 'react'

export class ContactSection extends Component {
  render() {
    return (
      <div className='bg-slate-50'>
        <div
          id='contact'
          className='flex-col space-y-6 py-8 sm:py-16 container max-w-8xl mx-auto '
        >
          <div className='flex text-2xl sm:text-4xl font-semibold justify-center'>
            <div>Contact</div>
          </div>
          <div className='flex flex-col-reverse sm:flex-row justify-center '>
            <div className='mx-auto sm:mx-12 mt-10 sm:mt-0'>
              <img
                src='/images/contact2.png'
                className='max-h-48 sm:max-h-64 sm:block'
                alt=''
              />
            </div>
            <div className='flex flex-col text-center sm:text-start space-y-2 '>
              <div>
                <div className='font-semibold'>Email:</div>
                <a
                  href='mailto:hi@a59corporation.com'
                  className='text-[#ff2d55]'
                >
                  hi@a59corporation.com
                </a>
              </div>
              <div>
                <div className='font-semibold'>LinkedIn:</div>
                <a
                  href='https://www.linkedin.com/in/skobeltcyn/'
                  target='_blank'
                  className='text-[#ff2d55]'
                  rel='noreferrer'
                >
                  Click here
                </a>
              </div>
              <div className='flex-1'></div>
              <div className='flex flex-col justify-center'>
                <div className='font-semibold'>A59 Corporation AB</div>
                <div className='text-gray-500'>559125-7265</div>
                <div>
                  <img
                    className='block mx-auto sm:mx-px'
                    src='/images/sweden.svg'
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ContactSection
