import React from 'react'
import ProjectComponent from './ProjectComponent'

export const ProjectsSection = () => {
  return (
    <div
      id='projects'
      className='flex-col space-y-6 py-8 sm:py-16 container max-w-8xl mx-auto'
    >
      <div className='flex text-2xl sm:text-4xl font-semibold justify-center'>
        <div>Projects</div>
      </div>
      <div className='flex flex-col px-12 sm:px-0 sm:flex-row justify-center  text-[#ff2d55] space-y-2 sm:space-y-0 sm:space-x-8 text-xl'>
        <ProjectComponent
          img={'/images/yourhabit.png'}
          title={'Your Habit'}
          link={'https://yourhabit.me'}
          desc={'A habit tracker app'}
          data={'20k+ downloads, 1000+ active users, 4.8/5 rating'}
          delayAnimation={100}
        />
        <ProjectComponent
          img={'/images/sensivo.png'}
          title={'Sensivo'}
          link={'https://sensivo.eu'}
          desc={'Web solution for handling Clinical Research data'}
          data={'Currently is using by Karolinska Institutet'}
          delayAnimation={200}
        />
        <ProjectComponent
          img={'/images/lagom.png'}
          title={'Lagom'}
          link={'https://skob.io/lagomwf'}
          desc={'Garmin application'}
          data={'0.5M+ downloads, 100k+ active users, 4.8/5 rating'}
          delayAnimation={300}
        />
      </div>
    </div>
  )
}

export default ProjectsSection
