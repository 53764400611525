import React from 'react'
import { useInView } from 'react-intersection-observer'

export const ClientSection = () => {
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  })
  return (
    <div className='flex-col space-y-6 py-8 sm:py-16 container max-w-8xl mx-auto'>
      <div className='flex text-2xl sm:text-4xl font-semibold justify-center'>
        <div>Happy Clients</div>
      </div>
      <div
        ref={ref}
        className={`transition-opacity duration-1000 ${
          inView ? 'opacity-100' : 'opacity-0'
        }`}
      >
        <div className='flex flex-col sm:flex-row justify-center  text-gray-500 space-y-2 sm:space-y-0 sm:space-x-8 text-xl'>
          <div>Sirius Point</div>
          <div>With Sara</div>
          <div>Tobii</div>
          <div>Brighter</div>
          <div>Karolinska Institutet</div>
          <div>NOX Consulting</div>
        </div>
      </div>
    </div>
  )
}

export default ClientSection
