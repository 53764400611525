import React, { Component } from 'react'

export class TopBar extends Component {
  render() {
    return (
      <div className='flex h-auto py-4 flex-col justify-start md:flex-row container max-w-8xl mx-auto'>
        <div className='justify-center mx-auto'>
          <div className='flex flex-row space-x-6'>
            <img src='images/logo.svg' className='logo' alt='logo' />
          </div>
        </div>
        <div className='flex-auto hidden md:block'></div>
        <div className='flex my-auto justify-center pt-5 md:pt-0  pr-2 text-center text-[#ff2d55] font-semibold text-lg space-x-6'>
          <a href='#projects'>Projects</a>
          <a href='#Service'>Service</a>
          <a href='#contact'>Contact</a>
        </div>
      </div>
    )
  }
}

export default TopBar
