import React, { Component } from 'react'
import Hero3dSection from './Hero3dSection'

export class HeroSection extends Component {
  render() {
    return (
      <div className='bg-gray-50'>
        <div className='container max-w-8xl mx-auto flex-col space-y-6 pt-16 md:px-12'>
          <div>
            <div className='flex text-4xl px-6 md:px-0 sm:text-6xl justify-center max-w-2xl mx-auto mt-12'>
              <div>
                Turning your{' '}
                <span className='underline decoration-[#ff2d55]'>ideas</span>{' '}
                into successful{' '}
                <span className='text-[#ff2d55] font-semibold'>products</span>
              </div>
            </div>
            <div className='text-gray-500 px-6 md:px-0 text-xl mt-4 sm:text-2xl max-w-2xl justify-center mx-auto'>
              Using our expertise in entrepreneurship and software development,
              we transform ideas into top-quality products
            </div>
          </div>
          <div className='h-96 pt-8'>
            <Hero3dSection />
          </div>
        </div>
      </div>
    )
  }
}

export default HeroSection
