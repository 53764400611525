import React, { Component } from 'react'

export class ServiceSection extends Component {
  render() {
    return (
      <div id='Service' className='flex-col py-16 container max-w-8xl mx-auto'>
        <div className='flex text-2xl sm:text-4xl font-semibold justify-center'>
          Service
        </div>

        <div className='text-xl text-gray-500 max-w-2xl mx-auto mt-8 px-12'>
          Our company specializes in developing software using a modern
          technological stack. We believe in staying up to date with the latest
          and greatest tools and technologies, and we strive to use the best of
          breed for each project. This allows us to deliver high quality,
          scalable, and reliable software solutions to our clients.
        </div>
        <div className='flex justify-center'>
          <img
            src='images/service.png'
            className='max-h-64 sm:max-h-96 max-auto'
            alt=''
          />
        </div>
        <div className='flex text-xl sm:text-2xl font-semibold justify-center mt-12'>
          <div>What are we using</div>
        </div>
        <div className='text-lg max-w-2xl mx-auto sm:text-xl flex flex-wrap px-12 sm:px-0 md:flex-row justify-center text-gray-500  gap-4 font-medium mt-8'>
          <div>HTML/CSS</div>
          <div>JavaScript</div>
          <div>Node</div>
          <div>Tailwind</div>
          <div>React</div>
          <div>Angular</div>
          <div>Flutter/Dart</div>
          <div>Java</div>
          <div>Android</div>
          <div>Figma</div>
          <div>Vim</div>
          <div>Photoshop</div>
          <div>Firebase</div>
          <div>ChatGPT/CoPilot</div>
        </div>
      </div>
    )
  }
}

export default ServiceSection
