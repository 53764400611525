import React from 'react'
import { useInView } from 'react-intersection-observer'

export default function ProjectComponent({
  img,
  title,
  link,
  desc,
  data,
  delayAnimation,
}) {
  const { ref, inView } = useInView({
    threshold: 0.5,
    delay: delayAnimation,
    triggerOnce: true,
  })
  return (
    <div
      ref={ref}
      className={`transition-opacity duration-1000 ${
        inView ? 'opacity-100' : 'opacity-0'
      }`}
    >
      <a
        href={link}
        target='_blank'
        rel='noreferrer'
        className='flex flex-col space-y-1 justify-center align-middle border-dashed border-2 border-gray-50 rounded-lg p-10 hover:border-red-500 hover:bg-orange-50 transition-colors ease-in-out hover:cursor-pointer duration-500 '
      >
        <div className='mx-auto'>
          <img src={img} alt={title} className='max-h-64 sm:max-h-64' />
        </div>
        <div className='text-xl sm:text-2-xl font-extrabold text-[#ff2d55]'>
          {title}
        </div>
        <div className='text-gray-700 text-xl font-medium'>{desc}</div>
        <div className='text-gray-500 text-base '>{data}</div>
      </a>
    </div>
  )
}
