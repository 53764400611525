import './App.css'
import ClientSection from './components/ClientSection'
import HeroSection from './components/HeroSection'
import TopBar from './components/TopBar'
import ProjectsSection from './components/ProjectsSection'
import ContactSection from './components/ContactSection'
import ServiceSection from './components/ServiceSection'

function App() {
  return (
    <div className='App font-nunito'>
      <div className=''>
        <TopBar />
        <HeroSection />
        <ClientSection />
        <ProjectsSection />
        <ServiceSection />
        <ContactSection />
      </div>
    </div>
  )
}

export default App
